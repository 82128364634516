// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/modules/customer/components/CustomersAutocomplete.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/modules/customer/components/CustomersAutocomplete.tsx");
  import.meta.hot.lastModified = "1733087018000";
}
// REMIX HMR END

import React from 'react';
import { SortOrder } from '~admin/generated/graphql';
import { AutocompleteInput } from '~common/components/form/autocomplete';
import { CustomersFetcher } from '../fetchers/customers-list-fetcher';
export const avatarRenderer = {
  avatarAccessor: item => item.customFields?.image ?? null,
  idAccessor: item => item.id,
  labelAccessor: item => item ? `${item.firstName} ${item.lastName}` : '',
  subLabelAccessor: item => item ? `(${item.emailAddress})` : ''
};
const defaultFilter = {
  take: 12,
  sortColumn: 'lastName',
  sortDirection: SortOrder.Asc
};
export const CustomersAutocomplete = ({
  label,
  placeholder,
  noBorder,
  showExistingButton,
  showSearchIcon,
  className,
  initialItem,
  selectedItem,
  onSelect
}) => {
  return <>
      <CustomersFetcher initialItem={initialItem} defaultFilter={defaultFilter}>
        {({
        items,
        loading,
        onSearchByQuery
      }) => {
        return <AutocompleteInput label={label} placeholder={placeholder} data={items} selectedItem={selectedItem !== undefined ? selectedItem : initialItem} renderer={avatarRenderer} loading={loading} noBorder={noBorder} showExistingButton={showExistingButton} showSearchIcon={showSearchIcon} className={className} onSelect={item => onSelect(item)} onSearch={onSearchByQuery} />;
      }}
      </CustomersFetcher>
    </>;
};
_c = CustomersAutocomplete;
var _c;
$RefreshReg$(_c, "CustomersAutocomplete");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;